import React from 'react';
import {Link} from "react-router-dom";
import {NavLink} from "react-router-dom";




const Nav = () => {
    return (
     
        <ul className="mainmenu">
         
            <li className="has-droupdown"><NavLink to="/"  style={({ isActive }) =>
                        isActive
                            ? {
                                color: '#059DFF',  
                            }
                            : {}
                    }>Home</NavLink></li>
            <li className="has-droupdown" ><NavLink  to="/medical"  style={({ isActive }) =>
                        isActive
                            ? {
                                color: '#059DFF',  
                            }
                            : {}
                    }>Medical Devices</NavLink></li>
    
            

            <li className="has-droupdown"><NavLink to="/contact"   style={({ isActive }) =>
                        isActive
                            ? {
                                color: '#059DFF',  
                            }
                            : {}
                    }>Contact</NavLink></li>
        </ul>
    )
}
export default Nav;
