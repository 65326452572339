import React from 'react';
import HeaderOne from "./HeaderOne";
import FooterTwo from "../pages/FooterTwo";
import Copyright from "../pages/Copyright";

const Layout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />
                {children}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default Layout;
