import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                How can I import my medical device to India?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>To import medical device in India one need to have registration certificate and importing license. To know more about CDSCO import license contact to our team.  </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                How is IEC different from medical device import license?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>IEC Import Export Code is the first requirement before you start an import/export code of any product in India. An IEC is important for the import and export of goods. The IEC is issued by the Directorate General of Foreign Trade (DGFT) Ministries of Commerce and Government of India. 

CDSCO Import License: Additional requirement for medical devices, the manufacturer has to adhere to various rules set by the national regulatory bodies of India. The main regulatory body of India is Central Drugs Standard Control Organization (CDSCO). All medical devices importers and manufacturers needs to mandatorily register the devices with CDSCO. After successful registration the medical devices can be imported in India.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                Does medical device import require license from Indian FDA?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Yes. CDSCO is generally referred locally by the name of Indian FDA. Hence any product registered under CDSCO will be elligible for import.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                What is the cost of an Import License for a medical device in India?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>The CDSCO/Government fees will vary according to risk classification of medical devices. So, firstly the classification of medical devices is must and then our team can guide you about the best suitable and affordable cost for licensing process.</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                How can I get an Import License in India?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>The importer should register with CDSCO first as the CDSCO grants the license application. After completing the necessary formalities and submissions, after reviewing application CDSCO either accepts or reject the proposal. To get Import license <span className='spanAccordionOne'>medical device regulatory consultants</span> can help as they know the whole process and work closely with the various notified and certified body. </Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

