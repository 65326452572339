import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";
import logo from '../Images/AK logo.svg'
import logo2 from '../Images/Ak logo white.svg'


const Logo = ({image, image2}) => {
    return(
            <div className="logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                        <img className="logo-light" src={logo2} alt="Corporate Logo" />
                        <img className="logo-dark" src={logo} alt="Corporate Logo" />
                    </Link>
            </div>
    )
}
Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;
